/********************************************************
* Prefix "v" applied to all classes 
* to seperate earlier stylesheets.
********************************************************/

.v-col-link, .v-col-count{
    display: inline-block;
}
.v-col-link{
    width: 50%;
    padding-left: 16px;
}
.v-col-count{
    width: 17%;
}
.v-col-count:last-child{
    width: 10%;
}

@keyframes v-blink {
    0% {
        opacity: .3;
    }
    20%{
        opacity: 1;
    }
    100%{
        opacity: .3;
    }
}

.v-link-loader div{
    color: #777;
    font-size: 24px;
    display: inline-block;
    line-height: 0.4;
    margin: 0 !important;
    padding: 0 !important;
    animation-name: v-blink;
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}
.v-link-loader div:nth-child(2){
    animation-delay: .5s;
}
.v-link-loader div:nth-child(3){
    animation-delay: 1s;
}

.v-grid-header {
    padding: 8px;
    background-color: #f4f4f4;
    margin-top: 18px;
    margin-bottom: 10px;
}
.v-accordion {
    /* width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    outline: none; */
}
.v-title-wrapper{
    padding: 8px;
    cursor: pointer !important;
    border-bottom: .5px solid #BBBBBB;
    
}

.bg-strip-dark, .bg-light-first .v-title-wrapper:nth-child(even), 
    .bg-dark-first .v-title-wrapper:nth-child(odd){
    /* background-color: #fbfbfb;; */
}
.v-title-wrapper:hover, 
.bg-dark-first .v-title-wrapper:hover,
.bg-light-first .v-title-wrapper:hover
{
    /* background-color: #e8f0f8; */
}
.v-fold{
    position: relative;
}
.v-fold .v-fold_trigger:before {
    transform-origin: 25%;
    display: inline-block;
    font-family: Glyphicons Halflings;
    content: "\E250";
    color: grey;
    transition: transform 200ms;
    transition-delay: .2s;
    font-size: 13px;
    padding-right: 10px;
}
.v-fold .v-fold_trigger.open:before {
    transform: rotateZ(90deg);
}
.v-fold .v-fold_content {
    height: 0;
    overflow: hidden;
    transition: max-height 700ms ease;
}
.v-fold .v-fold_content.open {
    height: auto;
}
.overlay-disable-expand{
    position: absolute;
    background: #fff;
    border: 1px solid #f9f9f9;
    height: 100%;
    width: 100%;
    opacity: .7;
    cursor: not-allowed;
}

.grid-row-header{
    width: 100%;
    padding:8px;
    background-color: #f4f4f4;
    margin-top: 18px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
}

.grid-row-header .grid-column , .grid-container .grid-column{
    width:25%;
}
.grid-row-header .grid-column:first-child, .grid-container .grid-column:first-child{
    width:50%;
}
.grid-container {
    width: 100%;
    padding:8px;
    display: flex;
}
.grid-container:nth-child(even){
    background-color: #fbfbfb;
}

.grid-container .grid-column.arrow:before{
    transform-origin: 25%;
    display: inline-block;
    font-family: Glyphicons Halflings;
    content: "\E250";
    color: grey;
    transition: transform 200ms;
    transition-delay: .2s;
    font-size: 13px;
    padding-right: 10px;
}

.automated-tab-1 {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 8px;
}
.automated-tab-2 {
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 24px;
}
.automated-tab-3 {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 8px;
}
.automated-tab-4 {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 8px;
}
.automated-margin-tab-4{
    margin-top: 18px;
}
@import url('fontface.css');
/* @import url('../node_modules/bootstrap/dist/css/bootstrap.min.css'); */
/**** importing the react-select CSS here ********/
@import url("react-select.css");
/**** importing the react-datePicker CSS here ********/
@import url("react-datepicker.css");

@import url("Study.css");

@import url("cohorts.css");

@import url("header.css");

@import url("modals.css");

@import url("technology.css");

@import url('DataPatients.css');

@import url('common.css');

@import url('documents.css');

@import url('all.css');

* {
	font-weight: normal;
}

html,
body,
.appcontainer {
	height: 100vh;
	display: block;
	/* overflow: scroll; */
	min-width: 1000px;
}
.login-container {
	height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.h5,
h5 {
	font-size: 24px;
}
label {
	display: block;
	font-size: 13px;
	color: #2b2929;
}

body > div > h1,
body > div > span {
	float: left;
	margin: 0 0 10px;
	padding: 0;
	width: 100%;
}

body > div > button {
	float: left;
	margin-right: 10px;
}

span {
	color: #2b2929;
}

button {
	padding: 7px 22px;
	width: auto;
}

body {
	font-family: "roboto-regular";
	font-size: 14px;
	overflow-x: hidden;
	color: #404040;
}

a {
	color: #297add;
	text-decoration: none;
}


article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}



.classLargeLbl {
	display: block;
	font-size: 20px;
	color: #666666;
}

/* .input {
	padding: 10px;
	font-size: 14px;
	border: 1px solid #999999;
	width: 200px;
	margin-bottom: 10px;
	border: 1px solid #e5e5e5;
	background: #ffffff;
	box-shadow: inset 1px 1px 2px rgba(200, 200, 200, 0.2);
} */

.input{
	background-color:#e8eeef;
	border-color:#000000;
	border-radius:5px;
	border-style:solid;
	border-width:1px;
	color:#384047;
	font-size:12px;
	line-height:18px;
	padding:20px 14px 5px;
}

.input-disabled {
	padding: 10px;
	font-size: 14px;
	border: 1px solid #999999;
	width: 200px;
	margin-bottom: 10px;
	border: 1px solid #e5e5e5;
	background: #fafafa;
	box-shadow: inset 1px 1px 2px rgba(200, 200, 200, 0.2);
}

.button {
	font-weight: bold;
	padding: 12px 15px;
	background: #00b5e0;
	color: #fff !important;
	font-size: 14px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	cursor: pointer;
	text-decoration: none;
	text-shadow: 0 1px 0px rgba(0, 0, 0, 0.15);
	border-width: 1px 1px 3px !important;
	border-style: solid;
	border-color: #326e99;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -moz-inline-stack;
	display: inline-block;
	vertical-align: middle;
	zoom: 1;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
	-moz-box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
	box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
}

.button-primary {
	background-color: #00b5e0 !important;
	border-color: #00b5e0 !important;
}

p.verifycode.verificationCode {
	text-align: left;
}

#verifyCode.verification_code {
	margin-top: 40px;
	margin-bottom: 44px;
}

p.code_v {
	margin-top: 24px !important;
}

.button-primary-nlp {
	font-weight: 100 !important;
	background-color: #00b5e0 !important;
	border-color: #00b5e0 !important;
	padding: 0px 3px !important;
	font-size: 14px !important;
}

.button-primary-disabled-nlp {
	font-weight: 100 !important;
	background-color: #cccccc !important;
	border-color: #999999 !important;
	padding: 0px 3px !important;
	font-size: 14px !important;
}

.nlp-highlight {
	background-color: #1f8f40 !important;
}

.nlp-highlight-disabled {
	background-color: #999999 !important;
}

.textarea-disabled {
	background: #cccccc !important;
}

.textarea-active {
	background: #ffffff !important;
}

.button-primary-disabled {
	background-color: #cccccc !important;
	border-color: #999999 !important;
}
.login-btn{
	align-items:flex-start;
	background-color:#00b5e0;
	border-color:#00b5e0;
	border-radius:5px;
	border-style:solid;
	border-width:1px;
	color:#ffffff;
	display:inline-block;
	font-size:12px;
	line-height:18px;
	margin:20px 0px 0px;
	padding:12px;
	text-align:center;
}

.msg {
	font-size: 11px;
	color: #666;
	padding: 10px;
}

#error {
	font-size: 14px;
	margin-top: 10px;
	text-align: center;
	color: #767676;
}

/***************** Login Css ******************/

/************* CSS for Login Screen of the design ****************/

#main {
	margin: 0 auto;
	width: 1300px;
	text-align: center;
}

.about {
	margin: 70px auto 40px;
	padding: 8px;
	width: 260px;
	font: 10px/18px "Lucida Grande", Arial, sans-serif;
	color: #666;
	text-align: center;
	text-shadow: 0 1px rgba(255, 255, 255, 0.25);
	background: #eee;
	background: rgba(250, 250, 250, 0.8);
	border-radius: 4px;
	background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
	background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
	background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
	-webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.1),
		0 0 6px rgba(0, 0, 0, 0.2);
	box-shadow: inset 0 1px rgba(255, 255, 255, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.1),
		0 0 6px rgba(0, 0, 0, 0.2);
}

.about a {
	color: #333;
	text-decoration: none;
	border-radius: 2px;
	-webkit-transition: background 0.1s;
	-moz-transition: background 0.1s;
	-o-transition: background 0.1s;
	transition: background 0.1s;
}

.about a:hover {
	text-decoration: none;
	background: #fafafa;
	background: rgba(255, 255, 255, 0.7);
}

.about-links {
	height: 30px;
}

.about-links > a {
	float: left;
	width: 50%;
	line-height: 30px;
	font-size: 13px;
}

.about-author {
	margin-top: 5px;
}

.about-author > a {
	padding: 1px 3px;
	margin: 0 -1px;
}

.containerNew {
	margin: 150px auto;
	width: 640px;
	text-align: center;
}

.profile-icon {
	margin-right: 10px;
	margin-top: 10px;
	vertical-align: middle;
	width: 27px;
}

.verify-icon {
	margin-right: 5px;
	margin-top: 10px;
	vertical-align: middle;
	width: 9%;
}
.sidebar-logo_img{
	color:#324054;
	display:inline;
	font-size:30px;
	font-weight:500;
	line-height:45px;
	margin:0px 0px 50px -30px;
	text-align:center;
	width: 239px;
    margin-bottom: 50px;
    margin-left: -88px;
	position: absolute;
}
.logo_img{
	color:#324054;
	display:inline;
	font-size:30px;
	font-weight:500;
	line-height:45px;
	margin:0px 0px 50px -30px;
	text-align:center;
	width: 250px;
    margin-bottom: 50px;
    margin-left: -30px;
}
.login {
	background-color: #ffffff;
    width: 50%;
    max-width: 650px;
    min-width: 500px;
    padding: 30px 50px;
    border-radius: 5px;
	height:490px;
}
.login.forgot-wrapper {
	height:577px;
}
.login.code-signature {
	height:520px;
}
.form-field-control {
	position: relative;
	margin-bottom: 1rem;
}
.form-field-label {
	font-size: 12px;
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	padding: 15px 12px 0px 12px;
	transition: all 0.2s;
	margin-bottom: 0;
	text-align: left;
	transform: translateY(-11px);
}
.form-field-input {
	background-color: #E8EEEF !important;
	padding: 20px 14px 5px !important;
	border-radius: 5px;
}
.form-field-input:placeholder-shown + .form-field-label {
	font-size: 14px;
	transform: translateY(0);
}
.form-field-input:focus + .form-field-label {
	font-size: 12px;
	transform: translateY(-11px);
}
.login:before {
	content: "";
	position: absolute;
	top: -8px;
	right: -8px;
	bottom: -8px;
	left: -8px;
	z-index: -1;
	border-radius: 4px;
}
.login-header{
	color: #324054;
    text-align: center;
    line-height: 1.5em;
    margin-bottom: 0.8em;
    margin-top: 0.5em;
	flex-flow: column;
    display: flex;
}
.forgot-header{
	color: #324054;
    text-align: center;
    line-height: 1.5em;
    margin-bottom: 0.8em;
    margin-top: 0.5em;
	flex-flow: column;
    display: flex;
	margin-bottom: 24px;
}
.logo-wrapper{
	display: flex;
    justify-content: center;
}

.error_field .alert {
	width: 400px;
	margin: 8px auto 0;
}
.login-error_field{
	color:#ff5f5f;
	display:inline;
	font-size:12px;
	line-height:18px;
	text-align:left;
	display: flex;
	margin-top: 15px;
}

.login h1 {
	background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #f0f2f2 0%, #ccd1d6 100%) repeat scroll 0 0;
	border-bottom: 1px solid #cfcfcf;
	border-radius: 3px 3px 0 0;
	box-shadow: 0 2px 4px #a4a9ad;
	color: #40424c;
	font-family: "roboto-regular";
	font-size: 19px;
	height: 56px;
	font-weight: normal;
	line-height: 40px;
	margin: -20px -20px 20px;
	padding: 0 20px;
	text-align: left;
}

.login h1 span {
	color: #2b2929;
	font-size: 16px;
	vertical-align: text-top;
}

.login p {
	margin: 18px 0 0;
	font-family: "roboto-regular";
	font-size: 15px;
}

.login p:first-child {
	margin-top: 0;
}

.login p.remember_me {
	float: left;
	line-height: 31px;
}

.login p.remember_me label {
	font-size: 13px;
	color: #777;
	cursor: pointer;
}

.login p.remember_me input {
	position: relative;
	bottom: 1px;
	margin-right: 4px;
	vertical-align: middle;
}

.login p.submit {
	text-align: center;
	margin: 40px 0px 36px 0px;
}

.login-help {
	margin: 20px 0;
	font-size: 11px;
	color: white;
	text-align: center;
	text-shadow: 0 1px #a4c0e1;
}

.login-help a {
	color: #619ae0;
	text-decoration: none;
}

.login-help a:hover {
	text-decoration: underline;
}

.login input {
	font-family: "roboto-regular";
	font-size: 14px;
}

.login_form {
	padding-top: 1px;
}

.login input[type="text"],
.login input[type="password"] {
	margin: 0;
	padding: 0 20px;
	width: 100%;
	height: 42px;
	color: #333 !important;
	font-family: "roboto-regular";
	background: white;
	border: none;
	border-radius: 2px;
	-moz-outline-radius: 3px;
	font-size: 14px;
	box-shadow: none;
}

.login input[type="text"]:focus,
input[type="password"]:focus {
	background-color: #ffffff !important;
    border-color: #EFEFEF;
    border: 1px solid #324054;
    border-radius: 5px;
}

.login input[type="text"]:focus,
input[type="password"]:focus,input:not([value=""]) {
    border-color: #EFEFEF !important;
    border: 1px solid #324054;
    border-radius: 5px;
}


.login :-moz-placeholder {
	color: rgb(185, 190, 194);
	font-size: 15px;
}

.login ::-webkit-input-placeholder {
	color: rgb(185, 190, 194);
	font-size: 15px;
}

.login p.submit button,.btn-forgot-pass {
	align-items:flex-start;
	background-color:#00b5e0;
	border-color:#00b5e0;
	border-radius:5px;
	border-style:solid;
	border-width:1px;
	color:#ffffff;
	display:inline-block;
	font-size:12px;
	line-height:18px;
	margin:0px 0px 0px;
	padding:12px;
	text-align:center;
	width: 100%;
	font-family: 'roboto-bold';
}

.login input[type="submit"]:active {
	background: #cde5ef;
	border-color: #9eb9c2 #b3c0c8 #b4ccce;
	-webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
	box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.login-text{
	color:#324054;
	font-size:30px;
	font-weight:500;
	line-height:45px;
	text-align:center;
	font-family: 'roboto-regular';
}
.singnature-text{
	font-size: 13px;
    line-height: 1.8;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 15px;
    color: rgba(0,0,0,0.54);
}
.login .forgot {
	color:#324054;
	display:inline;
	font-size:12px;
	font-weight:500;
	line-height:18px;
	margin:2.4px 0px 24px;
	text-align:center;
	text-decoration: underline;
}
.btn-code-verify{
	align-items:flex-start;
	border-color:#00ceff;
	border-radius:5px;
	border-style:solid;
	border-width:1px;
	font-family:'Avenir Next';
	font-size:12px;
	font-weight:700;
	margin:20px 0px 0px;
	text-align:center;
}
.login p:last-child {
	margin-top: 15px;
	margin-bottom: 10px;
}

.login input[type="text"] {
	margin-top: 15px;
}

.login input[type="password"] {
	margin-top: -3px;
}

p.verifycode.forget {
	color:#384047;
	font-family:'roboto-regular';
	font-size:12px;
	line-height:18px;
	margin:0px 0px 12px;
	text-align:center;
}

p.forgotpassword_email {
	margin-top: 36px;
}

p.submit.send_password {
	margin: 75px 0 45px 0;
}

.verifycode {
	margin-top: 15px;
	color: #2e3133;
}

#verifyCode {
	margin-top: 21px;
}

.login .panel-default {
	max-width: 360px;
}

@media (max-width: 1024px) {
	#main {
		width: 100%;
	}
}

@media (max-width: 568px) {
	section.containerNew {
		width: 100%;
	}

	.login {
		width: 100%;
	}

	.error_field .alert {
		width: 100%;
	}
}

/* bootstrap class overridden need to fix */
.form-control2 {
	margin-bottom: 10px;
	border-radius: 0;
	height: 26px;
}

/******************** Login Css end *********************/

/********** CSS for different Page Headings *********/

/*************** Heading Titles CSS that is used to display the headings
   on various pages *****************/

.customTopIconStyle {
	display: inline-block;
	/* margin-right: 10px;*/
	margin-left: 15px;
	/* vertical-align: middle;*/
	/*width: 30px;*/
}

.heading {
	/* color: #6b6d75; */
	display: inline-block;
	margin: 0;
	text-align: center;
	font-size: 22px;
	/* color: #40424c; */
	/* vertical-align: middle; */
	padding-left: 5px;
	vertical-align: top;
	line-height: 30px;
	padding-left: 13px;
}

.customTopIconStyle.cohortTopIcon {
	width: 38px;
	margin-right: 0;
}

.leftPadder {
	padding-left: 0;
	padding-top: 5px;
}

/********** CSS for different Page Headings end *********/

/********** Background Image CSS for login and Step2 pages **********/

.bg-image {
	align-items:center;
	background-color:#035b7f;
	color:#384047;
	display:flex;
	font-size:12px;
	justify-content:center;
	line-height:18px;
	text-align:center;
  }

/********** Background Image CSS end *****************/

/***************** SideBar CSS ****************/

.menu {
	display: block;
	height: 100%;
}

.menu > div {
	box-sizing: border-box;
	height: 100%;
	position: fixed;
	top: 0;
	/* -webkit-transition: -webkit-transform .25s ease 0s;
  transition: -webkit-transform .25s ease 0s;
  transition: transform .25s ease 0s;
  transition: transform .25s ease 0s, -webkit-transform .25s ease 0s; */
	width: auto;
	z-index: 2;
}

.menu > div.left {
	background: #292b2f none repeat scroll 0 0;
	/*left: -250px;*/
}

/* .menu>div.visible.left {
  -webkit-transform: translate3d(250px, 0, 0);
  transform: translate3d(250px, 0, 0)
} */
.menu > div > .menu-item {
	box-sizing: border-box;
	color: #b0b0b0;
	cursor: pointer;
	float: left;
	margin: 0;
	padding: 10px 15px;
	width: 100%;
}

.menu > div > .menu-item:hover {
	color: #f0f0f0;
}

#sidebar .navbar-brand {
	height: 100%;
	padding: 0;
	line-height: 26px;
	vertical-align: top;
	margin-right: 0px;
	width: 75px;
	background-color: #003655;
}

#sidebar .navbar-brand .menu-item {
	display: block;
	height: 44px;
	padding: 8px;
	text-align: center;
	width: 45px;
	margin-left: 10px;
}

.top_nav img {
	width: 60%;
}

.top_nav .nav-study {
	width: 24px;
	height: 24px;
}

.top_nav .nav-cohort {
	width: 24px;
	height: 24px;
}

.top_nav .nav-patient {
	width: 24px;
	height: 24px;
}

.top_nav .nav-data {
	width: 24px;
	height: 24px;
}

.top_nav .nav-technology {
	width: 18px;
	height: auto;
}

#navigation-menu {
	background: #0A3B5C none repeat scroll 0 0;
	display: inline-block;
	height: 100%;
	width: 210px;
}

.itemTitle.study_details {
	margin-bottom: 20px;
}

#navigation-menu ul {
	padding-left: 0;
}

#navigation-menu .menuplusicon {
	display: inline-block;
	width: 100%;
	height: 36px;
}

#navigation-menu .menuplusicon span {
	color: #fff;
}

#sidebar {
	height: 100vh;
	display: inline-block;
	width: 20%;
	max-width: 240px;
}

.bottom_nav {
	bottom: 0;
	position: absolute;
}

#sidebar-content {
	height: 100%;
	padding-left: 10px;
	padding-right: 10px;
	width:240px;
}

#sidebar .navbar-brand .active {
	background-color: #0A3B5C;
}

#sidebar .menu .visible ul {
	display: inline-block;
	margin-bottom: 0;
	height: 100%;
	list-style: outside none none;
	padding-left: 0;
	width: 100%;
}

.dataNavigation .filterTab a.filterText,
.dataNavigation .filterTab a.filterText:hover {
	background: #446D82;
	border-radius: 3px;
	color: #ccc;
	display: inline-block;
	line-height: 17px;
	padding: 10px;
	font-size: 11px;
	width: 98%;
	text-decoration: none;
	position: relative;
	/* white-space: nowrap; */
	word-break: break-all;
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
}

.dataNavigation .historyTab li {
	text-align: left;
}

.dataNavigation .historyTab li span {
	color: #00b5e0;
	font-size: 13px;
	font-weight: 700;
}

.dataNavigation .historyTab li p {
	color: #c7c7c7;
	font-weight: 700;
	font-size: 11px;
}

.dataNavigation .historyTab li > a {
	background: #446D82;
	border-radius: 3px;
	color: #ccc;
	display: inline-block;
	line-height: 17px;
	margin-bottom: 5px;
	padding: 10px;
	font-size: 11px;
	width: 98%;
	text-decoration: none;
	word-break: break-all;
}
.dataNavigation .historyTab li a:hover {
	color: #ccc;
}
.dataNavigation .historyTab ul li a strong {
	display: inline-block;
	color: #ccc;
	line-height: 14px;
	overflow-wrap: break-word;
	width: 100%;
}

.logo-container{
    height: 125px;
    display: flex;
    align-items: flex-start;
    padding: 25px 0 44px 31px;
}

.dataNavigation .topMargin {
	margin-top: -12px;
	display: inline-block;
	width: 100%;
}

.dataNavigation i {
	float: right;
	color: #6d6d6d;
}

.removeCross {
	position: absolute;
	top: 12px;
	right: 8px;
}

.dataNavigation ul {
	width: 100%;
}

.dataNavigation .topMargin ul {
	text-align: left;
	font-size: 11px;
}

.dataNavigation .topMargin ul li span {
	color: #99caba;
	font-weight: 700;
	font-size: 13px;
}

.dataNavigation .topMargin ul li p {
	color: #ccc;
	font-size: 13px;
	margin: 3px;
}

.dataNavigation .topMargin ul li {
	min-height: 35px;
	line-height: 28px;
	height: 40px;
	position: relative;
	position: relative;
	display: inline-block;
	width: 100%;
}

/******************* SideBar CSS end *********************/

/******************* CSS for fade in effect on Step2 Page *****************/

.example-appear {
	opacity: 0.01;
}

.example-appear.example-appear-active {
	opacity: 1;
	transition: opacity 500ms ease-in;
}

.example-enter {
	opacity: 0;
  }
  .example-enter-active {
	opacity: 1;
	transition: opacity 200ms;
  }
  .example-exit {
	opacity: 1;
  }
  .example-exit-active {
	opacity: 0;
	transition: opacity 200ms;
  }

/************* Fade in Effect CSS end *****************/

/*********** Removing Text Decoration from achor tags on hover Globally**********/

a:hover {
	text-decoration: none;
}

/********** Anchor Tag Text Decoration CSS end **********/

/*********** Data List Oraganization patients list CSS *************/
.oddEvenStyle .customStyle .panel-default.txtfile:nth-of-type(odd) {
	background-color: #fff;
}

.oddEvenStyle .customStyle .panel-default.txtfile:nth-of-type(2n) {
	background-color: #fbfbfb;
}
/*********** Data List Oraganization patients list end CSS *************/

/******************* Automated Annotation Details Tooltip CSS*****************/
.automatedTooltip {
	min-height: 350px;
}
.automatedTooltip p {
	border-bottom: 1px solid #ccc;
	font-size: 22px;
	padding: 10px 20px;
}

.automatedTooltip ul {
	padding: 20px;
}

.automatedTooltip ul li {
	font-size: 17px;
	padding: 5px 20px;
	color: #619ae0;
	list-style-type: none;
}

.automatedTooltip ul li span {
	color: #a5a5a4;
}

.automatedTooltip ul li:hover {
	background: #e8f0f8;
}

.automatedTooltip.details table.padding {
	display: inline-block;
	padding: 10px;
}

.automatedTooltip.details td {
	color: #a5a5a4;
	width: 300px;
	color: #333;
	font-size: 13px;
}

.automatedTooltip.details td strong {
	display: block;
	color: #333;
	font-size: 13px;
	word-spacing: 0;
	background: #f4f4f4;
	padding: 2px 1px;
	font-weight: 500;
	margin-bottom: 2px;
	padding-left: 15px;
}

.automatedTooltip.details span.view {
	padding-left: 15px;
	display: block;
	/* font-size: 13px;
  padding: 4px 6px;
  margin-bottom: 4px;
  display: block; */
	/* color: #3980d8; */
}

.grid-container {
    display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	grid-template-columns: repeat(2, 2fr);
    grid-gap: 10px;
    padding: 20px 10px 10px 10px;
  }
  
  .grid-container-1 {
    grid-template-rows: auto auto auto auto auto auto auto auto auto auto;
  }
  .grid-container-2 {
    grid-template-rows: auto auto auto auto auto auto auto auto;
  }
  .grid-container-3 {  
    grid-template-rows: auto auto auto auto auto auto auto auto auto auto ;
  }
  .grid-container-3-column {  
	grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .grid-container span strong {
    display: block;
	color: #333;
	font-size: 13px;
	word-spacing: 0;
	background: #f4f4f4;
	padding: 2px 6px;
	font-weight: 500;
	margin-bottom: 2px;
	padding-left: 15px;
  }
  .grid-container span.code_type{
	padding-left: 10px;
	font-size: 13px;
	white-space: nowrap;
  }
  .grid-container .error {
	font-size: 13px;
	color: #ea5848;
	padding-left: 10px;
  }
/******************* Automated Annotation Details Tooltip CSS end *****************/

/*********** Aligning the left hand side div to match the design *****************/

.main_left_div {
	width: 80%;
	padding: 0 0 0 70px;
	min-height: calc(100vh - 60px);
	display: inline-block;
	position: relative;
	vertical-align: top;
	min-width: 700px;
	/* overflow: scroll; */
}

/************** Div Align CSS end *****************/

/*********************** CSS end for Inner Components ******************/

/*************** Logout Modal CSS to match the design *******************/

.testClass a.narcissus_86uon7 {
	display: none;
}

.testClass {
	min-width: 50%;
	border-radius: 0;
	/* width: 670px !important;
  border-radius: 0;
  height: 240px;
  left: 396px !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); */
}

.data_icon {
	display: inline-block;
	margin-right: 5px;
	margin-left: 0;
	width: 20px;
}

.jconfirm-box-container {
	display: inline-block;
	margin-left: 0;
	text-align: center;
	width: 100%;
}

.jconfirm-box.jconfirm-hilight-shake {
	width: 670px;
	height: 240px;
	border-radius: 0 !important;
}

.jconfirm-content-pane {
	margin-top: 42px;
	color: #333;
	font-size: 14px;
	margin-bottom: 40px !important;
	text-align: center;
}

.jconfirm-buttons {
	float: none !important;
	text-align: center;
}

.jconfirm-buttons button.btn-default {
	border: none !important;
	background: #00b5e0 !important;
	border-color: #b4ccce #b3c0c8 #9eb9c2 !important;
	-o-border-image: none !important;
	border-image: none !important;
	border-radius: 5px !important;
	border-style: solid !important;
	border-width: 1px !important;
	box-sizing: content-box !important;
	color: #fff !important;
	font-size: 16px !important;
	height: 38px !important;
	margin-right: 10px !important;
	outline: 0 none !important;
	padding: 0 18px !important;
	width: 120px !important;
	font-size: 16px !important;
	text-transform: capitalize !important;
	text-shadow: none;
}

.jconfirm-buttons .btn-default:nth-child(1) {
	margin-left: 10px !important;
}

/*********************** Logout modal css end  ******************/

.Select-input input {
	border: medium none !important;
}

/************** React Loading Spinner CSS *********************/

/****** The loading spinner is used to show the loading to the user
while the data gets populated in the tooltip ********/

.defaultSpinner {
	height: 120px;
	width: 120px;
	border-radius: 100%;
	display: block;
	position: relative;
	top: 0px;
	margin: auto;
	z-index:9999;
	/* background-color: transparent; */
	/* border: 5px solid transparent; */
	/* border-color: #eee #eee #444 #444;
  -webkit-animation: rotateLoading 1s linear 0s infinite normal;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: rotateLoading 1.5s linear 0s infinite normal;
  -moz-transform-origin: 50% 50%;
  animation: rotateLoading 1.5s linear 0s infinite normal;
  transform-origin: 50% 50%;
  transition: all 0.1s ease-in; */
	background-image: url("../images/default_spinner.svg");
	background-size: contain;
	background-repeat: no-repeat;
}

.popupSpinner{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
}

@keyframes rotateLoading {
	0% {
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
	}
}

@-webkit-keyframes rotateLoading {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes rotateLoading {
	0% {
		-moz-transform: rotate(0deg);
	}

	100% {
		-moz-transform: rotate(360deg);
	}
}

.loading {
	text-align: center;
	height: 100vh;
	width: 100vw;
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
	/* background: rgba(192, 192, 192, .2); */
}
.annotaionListPopover .loading {
	text-align: center;
	height: 100%;
	width: 100%;
	display: block;
	position: relative;
	left: 0;
	top: 0;
	z-index: 9999;
	/* background: rgba(192, 192, 192, .2); */
}

/************** React Loading Spinner CSS end ********************/

/**************23Aug popover-css **************/

/************* Changes in popover CSS to match the design  **************/
.popover {
	z-index: 1100 !important;
}

.popover-body {
	min-height: 200px;
	padding: .5rem .75rem .1rem .75rem;
}
.popover-body ul {
	padding: 0;
	margin: 0 0 5px;
}

.popover-body ul li a {
	font-size: 14px;
	font-family: "roboto-regular";
}

.popover-body ul li:hover {
	background: #e8f0f8;
}

.popover-body ul li {
	margin: 0;
	background: #f5f5f5;
	margin-bottom: 2px;
	padding: 4px 10px;
	height: 100%;
	line-height: 1.5;
}

.popover-body ul li:nth-child(even) {
	background: #fff;
	margin: 0;
}

.historyData {
	font-size: 13px;
	line-height: 1.5;
	padding-left: 50px;
}

h3.popover-title {
	font-size: 18px;
	padding: 15px 15px 6px;
	color: #333;
	background: transparent;
	font-family: "roboto-regular";
}

.annotaionListPopover h3.popover-title {
	border-bottom: 1px solid #ebebeb !important;
	margin: 0 15px 0 15px;
}

/**************23Aug popover-css**************/

/****************** Cohort Add and Edit Pages CSS ****************/

.technologyDiaInner.cohortsInner .technologyDia span strong {
	color: #4a90e2;
	font-family: Helvetica Bold;
	font-weight: 400;
	font-size: 18px;
}

.cohortsInner .technologyDia {
	margin-bottom: 0;
}

.cohortsInner .mainTrialEntries label {
	color: #40424c;
	font-family: "roboto-regular";
	font-size: 15px;
	padding-right: 0;
	font-weight: normal;
	width: 45%;
	display: inline-block;
}

.cohortsInner .mainTrialEntries .enterCohort label {
	width: auto;
}

.patientNameTitle.lexicon_title h4,
.enterCohort.lexicon_title label,
.enterCohort.studydetail.detail_content.lexicon_title label {
	font-size: 20px;
	font-weight: normal;
}

.cohortsInner .mainTrialEntries td label {
	width: 100%;
}

.cohortsInner .mainTrialEntries .enterCohort.lexicon_title input {
	/* font-size: 20px;
  font-weight: 600;*/
}

.addTrialArm.saveTrial .appendCohortData td label span {
	padding-left: 20px;
}

.cohortsInner .mainTrialEntries .enterCohort {
	padding: 0 0 0 23px;
}

.cohortsInner .mainTrialEntries input {
	border: 1px solid #ccc;
	border-radius: 3px;
	font-size: 14px;
	/* padding-left: 12px; */
	width: 100%;
}

.enterCohort {
	margin-top: 10px;
	margin-bottom: 6px;
}

.cohortsInner .mainTrialEntries select.custom {
	border: 1px solid #ccc;
	border-radius: 3px;
	font-size: 24px;
	padding: 5px;
	width: 75%;
	background: transparent;
	height: 40px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.appendCohortData label {
	/* width: auto !important; */
}

/* .Select-placeholder,
.Select--single>.Select-control .Select-value {
  top: 0px;
  padding-left: 9px;
  margin-bottom: auto;
  line-height: 26px;
} */

.addCohortCriteria #appendDiv .appendCohortData td label span {
	color: #619ae0;
}

/* .techitemTitle {
  margin-top: 20px !important;
} */
.rdtPicker {
	width: 100%;
	min-width: 100%;
}

.addCohortCriteria {
	display: inline-block;
	margin-bottom: 10px;
	margin-top: 10px;
	width: 100%;
}

.addCohortCriteria .cohortpanelBg label {
	font-size: 15px;
	margin: 0;
}

/* .Select.uniqueTriaArm.custom.Select--single.is-clearable.is-searchable .Select-control .Select-input {
  height: 24px;
} */

.criteriaSection .Select.uniqueTriaArm.custom {
	margin-left: 0;
	width: 75%;
}

.criteriaSection.cs .Select.uniqueTriaArm.custom {
	margin-left: 0;
	width: 74%;
}

.addCohortCriteria .cohortpanelBg {
	background: #f4f4f4;
	display: inline-block;
	padding: 6px;
	width: 100%;
}

.addCohortCriteria .addCohortNew img {
	width: 24px;
	margin-right: 10px;
}

.addCohortCriteria .addCohortNew img.App-plusicon {
	/* margin-right: 6px; */
	width: 24px;
}

.appendCohortData {
	padding-left: 49px;
}

.editCohort_inclusion {
	margin-bottom: 6px;
}

.addEventDate.calender img, input[type = 'image']  {
	margin-bottom: 5px;
	margin-right: 8px;
	vertical-align: middle;
	width: 35px;
    height: 35px;
}

.addEventDate select,
.addOutcome select {
	border: 1px solid #ccc;
	border-radius: 3px;
	font-size: 14px;
	padding: 5px;
	margin-right: 10px;
	width: 20%;
	background: transparent;
	height: 26px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.editCohortIcon img {
	width: 29px;
	/* border: 1px solid #00B5E0; */
	padding: 5px;
	cursor: pointer;
}

.addCohortCriteria.inclusion_exclusion {
	margin-bottom: 15px;
}

.appendCohortData.outComeLists td td {
	height: 40px;
	padding-bottom: 0;
	padding-top: 0;
}

.appendCohortData .criteriaSection.cs label {
	width: 25%;
}

.appendCohortData .criteriaSection.cs .Select.uniqueTriaArm.custom {
	margin-left: 0;
	width: 75%;
}

.Select.uniqueTriaArm.custom.cohort_selext_x.Select--single.is-clearable.is-searchable.has-value span.Select-clear {
	display: none;
}

.cohortsInner .mainTrialEntries .enterCohort label.cohort_name {
	width: 18%;
}

.InclusionExclusion {
	 width: 30px;
	 height:30px;
	float: left;
}
.minusIcon-container{
	display: inline-block;
	padding-right:10px;
}

.set_position {
	position: relative;
}

.Select.uniqueTriaArm.custom.cohort_selext_x.minusInclusion.Select--single.is-clearable.is-searchable.has-value {
	width: 83%;
}

/****************** Cohort Add and Edit Pages CSS end ****************/
.addOutcome.saveTrial {
	padding-left: 20px;
	margin-top: 10px;
}

.addTrialArm.saveTrial.col-sm-11 {
	margin-top: 10px;
}

.appendCohortData.measures_outcome select {
	margin: 1px 6px 0 5px;
}

/*********** Button CSS for add and edit pages **************/
.trialButton {
	padding-bottom: 20px;
}

.trialButton button {
	color: #fff;
	font-size: 16px;
	padding: 7px 20px;
	border: none;
	border-radius: 3px;
	margin-right: 10px;
}

.trialButton .trialArm {
	background: #00b5e0;
}

.trialButton .trialArm.secondary {
	color: #ddd;
}

.trialButton .trialOutcome {
	background: #4a90e2;
}

/*********** Button CSS for add and edit pages end **************/

/**************** Error CSS for Cohort Add and Edit pages  *****************/
.v-cohort-input-holder .help-block{
	display: block;
}

.help-block.error {
	color: #ea5848;
}

/***************** Error CSS end **************/

/*************** Panels and Accordion CSS *****************/

.itemTitle {
	background: #f4f4f4;
	height: 36px;
	padding: 0;
	margin-bottom: 10px;
}
.technology-page .itemTitle {
	background: #f4f4f4;
	height: auto;
	padding: 0;
	margin: 10px 0;
}

.itemTitle h3 {
	color: #2c2c2c;
	font-size: 15px;
	margin: 0;
	padding-bottom: 9px;
	padding-top: 9px;
}

.techNo h3 {
	padding-bottom: 12px;
	padding-top: 12px;
}

.ToogleSection {
	display: inline-block;
	width: 100%;
}

.panel-group {
	margin: 0px;
}

.ToogleSection .panel.panel-default {
	border: medium none;
	border-radius: 0;
	margin-bottom: 0;
}

.ToogleSection .panel.panel-default .panel-heading:nth-of-type(odd) {
	border: medium none;
	border-radius: 0;
	padding: 0;
}

.ToogleSection .panel.panel-default .panel-heading.even,
.ToogleSection .panel.panel-default .panel-heading:nth-of-type(2n) {
	background-color: #fff;
	border: medium none;
	border-radius: 0;
	padding: 0;
}

.ToogleSection .panel.panel-default .panel-heading.odd {
	background-color: #fbfbfb;
	border: medium none;
	border-radius: 0;
	padding: 0;
}

.ToogleSection1 .accordion .leftArrow:nth-of-type(odd) {
	background: #fbfbfb;
}

.ToogleSection1 .accordion .leftArrow:nth-of-type(2n) {
	background: #fff;
}

.ToogleSection .panel.panel-default .panel-heading h4 {
	margin: 0;
	padding: 7px 0;
	width: 100%;
}

.ToogleSection .panel.panel-default .panel-heading h4 a {
	text-decoration: none;
	cursor: pointer;
}

.ToogleSection .panel.panel-default .panel-body {
	background: #fff none repeat scroll 0 0;
	border: medium none;
}

.tooglecontent .col-sm-6 > div {
	padding: 20px 20px 20px 0;
}

.tooglecontent .col-sm-6 span {
	color: #95bbea;
	font-size: 16px;
}

.ToogleSection #accordion .panel-body span {
	color: #4a90e2;
	font-size: 13px;
	font-weight: 400;
}

#accordion1 .panel-body td.col-sm-6 {
	display: inline-block;
}

.panel.panel-default span {
	color: #619ae0;
	font-size: 15px;
}

.ToogleSection .panel.panel-default .panel-heading.inactive {
	background: #e8f0f8;
}

.ToogleSection .panel.panel-default .panel-heading.active {
	background: #fbfbfb;
}

.ToogleSection .panel.panel-default.active .panel-heading {
	margin-top: 5px;
}

#accordion1 .panel-title img {
	/*padding: 7px*/
}

.panel-heading .accordion-toggle:before {
	font-family: Glyphicons Halflings;
	content: "\E252";
	float: left;
	color: grey;
	font-size: 13px;
	padding-right: 10px;
	margin-top: 3px;
}

.panel-heading .accordion-toggle.collapsed:before {
	content: "\E250";
	font-size: 13px;
}

.panel-heading .accordion-toggle.deactive:before {
	content: "";
	font-size: 13px;
}

#accordion .panel.panel-default {
	background: none;
	margin-bottom: 0px;
	box-shadow: none;
}

div[id^="collapseFour"],
div[id^="collapseThree"] {
	/* margin-top: 5px */
}

#collapseTwo .leftPadder .panel-default .panel-heading .panel-title {
	/*padding-left: 35px*/
}

#collapseTwo div[id^="collapseThree"] .panel-default .panel-heading .panel-title {
	/* padding-left: 55px*/
}

#collapseTwo div[id^="collapseThree"] div[id^="collapseFour"] .panel-default .panel-heading .panel-title {
	/*padding-left: 75px*/
}

#collapseTwo .panel-title .col-sm-5,
div[id^="collapseThree"] div[id^="collapseFour"] .leftPadder .panel-default .panel-title .col-sm-5 {
	padding-left: 5px;
}

.technologyTab .panel-heading .panel-title {
	display: inline-block;
	height: 35px;
	margin: 0;
	padding: 2px 10px;
	width: 100%;
}

.technologyTab #accordion .panel.panel-default {
	border: medium none;
	border-radius: 0;
	margin-bottom: 10px;
}

.cohortsTab .technologyDia strong {
	color: #2c2c2c;
	font-size: 16px;
}

.named_entity .technologyNamed h4 {
	color: #424242;
	font-size: 15px;
}

.technologyTab .mainAccordian {
	margin-bottom: 12px;
}

.ToogleSection1 {
	display: inline-block;
	width: 100%;
}

.technologyTab.customInnerPatientsTab .mainAccordian h5 {
	margin: 0;
	font-size: 15px;
	line-height: 24px;
}

.technologyTab #accordion .panel-heading {
	padding: 0;
}

.patientsNewTab .technologyTab #accordion .panel-collapse .panel-heading {
	height: 63px;
	background-color: #fbfbfb;
	border-color: #fbfbfb;
}

.patientsNewTab .technologyTab #accordion .panel-collapse .panel-heading:hover {
	background-color: #e8f0f8;
}

.patientsNewTab .technologyTab #accordion .innerPaddingLeft {
	padding-left: 15px;
}

.technologyTab {
	text-align: left;
}

.ToogleSection1 .accordion-toggle {
	text-decoration: none;
	outline: none;
}

.ToogleSection1 .panel-collapse .panel-heading span {
	color: #4a90e2;
	font-size: 14px;
}

.itemTitle.gridView {
	display: none;
}

.grid-group-item-part .itemTitle.gridView {
	display: block;
}

.ToogleSection .panel.panel-default .panel-heading:hover {
	background-color: #e8f0f8;
}

.pagination a span.glyphicon {
	color: #337ab7;
}

.customInnerPatientsTab h3,
.customInnerPatientsTab h5,
.patientNameTitle h4 {
	color: #6b6d75;
	font-size: 15px;
	margin-bottom: 0;
	margin-top: 0;
	display: inline-block;
}

.customInnerPatientsTab h3 {
	/*font-weight: 600*/
}

.patientNameTitle h4 {
	margin-top: 10px;
	margin-bottom: 15px;
	color: #40424c;
	font-size: 20px;
}

.technologyTab.customInnerPatientsTab .mainAccordian {
	margin-left: 30px;
	padding-left: 40px;
}

.technologyTab.customInnerPatientsTab .mainAccordianBelow span,
.technologyTab.customInnerPatientsTab .mainAccordian span {
	color: #619ae0;
}

/* .technologyTab.customInnerPatientsTab .mainAccordian span.customName {
  font-family: Helvetica Bold;
  font-size: 34px;
  vertical-align: middle
} */
.technologyTab.customInnerPatientsTab .mainAccordianBelow {
	margin-left: 40px;
	padding-left: 50px;
}

.customInnerPatientsTab .innerPatientlist .itemTitle h3 {
	font-size: 15px;
	color: #222;
}

.leftArrow .panel-default .panel-collapse.accordianID.collapse.in {
	display: inline-block;
	margin: 0;
	width: 100%;
}

.leftArrow .panel-default .panel-collapse.accordianID.collapse {
	padding: 10px 13px 7px 12%;
}

.leftArrow .panel-default .panel-heading .panel-title {
	padding-left: 50px;
}

#accordion .leftArrow .panel-default {
	margin-bottom: 5px;
}

.leftArrow .panel-default .panel-heading {
	background-color: #fbfbfb;
	border-color: #fbfbfb;
}

/*************** Panels and Accordion CSS end *****************/

/*********** Document Name Heading CSS **********/

.historyTitle label {
	color: #40424c;
	font-family: "roboto-regular";
	font-size: 18px;
	font-weight: normal;
	padding: 8px 0 10px 0;
}

.historyTitle label span {
	color: #156bd6;
}

.historyTitle {
	padding-top: 0px;
	padding-bottom: 7px;
}

.patientsTab.DataDetail {
	margin-bottom: 0 !important;
}

/*********** Document Name Heading CSS end**********/

/******************* CSS for Inner components *****************/

.App {
	text-align: center;
}

.App-logo {
	-webkit-animation: App-logo-spin infinite 20s linear;
	animation: App-logo-spin infinite 20s linear;
	height: 80px;
}

.App-header {
	background-color: #222;
	height: 150px;
	padding: 20px;
	color: #fff;
}

.App-intro {
	font-size: large;
}

@-webkit-keyframes App-logo-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
}

@keyframes App-logo-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
}

.techitemTitle.thead,
.technologyTabInner .techitemTitle {
	background: #f4f4f4 none repeat scroll 0 0;
	height: 40px;
	color: #000;
	font-weight: 800;
	margin-bottom: 5px;
	margin-top: 20px;
}

.technologyTabInner tbody .inner {
	height: 60px;
}

.technologyTabInner tbody .inner span {
	color: #4a90e2;
	font-size: 13px;
	font-weight: 800;
}

.technologyTabInner tbody .inner td:last-child {
	font-size: 13px;
	font-weight: 800;
}

.technologyTabInner tfoot input {
	width: 50px;
}

.technologyTabInner tbody td,
.technologyTabInner thead td {
	padding-left: 15px;
}

.technologyTabInner .techitemTitle h3 {
	font-size: 15px;
	color: #40424c;
	display: inline-block;
	border-radius: 3px;
	margin: 0;
	padding: 12px 0 12px;
}

.technology .techitemTitle h3 {
	font-size: 14px;
	display: inline-block;
	border-radius: 3px;
	margin: 0;
	padding: 10px 0 10px;
	letter-spacing: 1px;
	color: rgba(0, 0, 0, 0.9);
}

.technology .techitemTitle {
	background: #f4f4f4 none repeat scroll 0 0;
	color: #000;
	font-weight: 800;
	margin-bottom: 5px;
}

.technologyDiaInner .technologyDia {
	background: #f3f1f1;
	padding: 4px;
	margin-bottom: 10px;
	margin-top: 10px;
}

/*.itemTitle .records h3{
  color: #6b6d75;
  font-size: 26px;
  margin-bottom: 0;
  margin-top: 0;
  height: 60px;
  margin: 0;
  padding-bottom: 15px;
  padding-top: 23px;
  font-weight: 400;
}
*/
.technologyTabInner .tech-inner-content {
	display: inline-block;
	width: 100%;
}
/*
FOLLOWING COMMENTED CODE MOVED TO common.css

.tech-inner-content .tech-inner-content-item {
  display: inline-block;
  margin: 0;
  padding:6px 0;
  min-height: 34px;
  width: 100%;
}

.tech-inner-content:nth-child(odd) {
  background: rgb(251, 251, 251);
  background: rgb(251, 100, 100);
}

.tech-inner-content:nth-child(even) {
  background: #fff;
}

.tech-inner-content .tech-inner-content-item span {
  color: #2687da;
  overflow-wrap: break-word;
  font-size: 15px;
}

.tech-inner-content:hover {
  background: #e8f0f8
}
*/
.technologyTabInner .menuplusicon {
	margin-top: 10px;
}

.technologyTabInner .menuplusicon img {
	vertical-align: middle;
	width: 20px;
}

.tech-inner-content-table .title td span {
	color: #424242;
	font-size: 16px;
	font-weight: 700;
}

.tech-inner-content-table .inner td span {
	color: #619ae0;
	font-size: 14px;
}

.technologyRule span {
	color: #4a90e2;
}

.technologyDiaInner {
	text-align: left;
}

.named_entity {
	margin-top: 30px;
}

.loading img {
	width: 20px;
}

.cohortsTab .inner span span {
	color: #afafaf;
}

.cohortsTab .technologyDia span strong {
	color: #4a90e2;
	font-size: 17px;
}

.cohortsTab .tech-inner-content-table {
	margin-top: 10px;
	margin-bottom: 30px;
}

.inputTab {
	display: inline-block;
}

.technologyTabInner {
	text-align: left;
}

.lexiconTab .technologyDiaInner .technologyDia h4 {
	font-size: 15px;
	margin: 0;
	padding: 6px;
}

.lexiconTab .technologyDiaInner .tech-inner-content-table .title td span {
	font-family: "roboto-bold";
	font-size: 14px;
}

.cohortsTab .technologyDiaInner .tech-inner-content-table .inner td span,
.lexiconTab .technologyDiaInner .tech-inner-content-table .inner td span {
	font-size: 14px;
}

.named_entity .technologyRule strong,
.technologyDia .patentsTitle {
	font-family: Helvetica Bold;
	font-size: 13px;
	letter-spacing: 0;
	font-weight: 400;
	color: #2c2c2c;
}

.technologyDiaInner.cohortsInner .tech-inner-content-table span span {
	color: #888;
}

.cohortsInnerPageCotent table {
	width: 100%;
}

.cohortsInner .tech-inner-content-table {
	margin-bottom: 30px;
}

.cohortsInner .tech-inner-content-table table {
	line-height: 20px;
}

.technologyTabInner.cohortsInnerPageCotent .menuplusicon {
	background: #00b5e0 none repeat scroll 0 0;
	border-radius: 3px;
	display: inline-block;
	float: right;
	margin-right: 10px;
	padding: 5px;
	text-align: right;
}

.patientsNewTab .named_entity .technologyNamed .diagnoses,
.patientsNewTab .tech-inner-content-table .title td span {
	color: #2c2c2c;
	font-size: 18px;
	font-weight: 700;
}

.patientsNewTab .named_entity .technologyRule a span,
.patientsNewTab .tech-inner-content-table .inner td span {
	color: #619ae0;
	font-size: 16px;
	line-height: 18px;
	font-weight: 700;
}

.cohortsInner.trialsInner .trialCohPat {
	/*border-bottom: 2px solid #ebebeb;*/
	padding-bottom: 20px;
	display: inline-block;
	width: 100%;
}

.cohortsInner.trialsInner .trialCohPat.lastDiv {
	border-bottom: none;
}

.cohortsInner.trialsInner .technologyDia {
	margin-bottom: 10px;
}

.cohortsInner.trialsInner .tech-inner-content-table {
	display: inline-block;
	margin-bottom: 30px;
	margin-top: 10px;
	width: 100%;
}

.technologyDiaInner.trialsInner .diabetics {
	color: #4a90e2;
	font-size: 22px;
	display: inline;
}

.trialsInner .mainTrialEntries .rightIcons {
	text-align: right;
}

.trialsInner .mainTrialEntries .rightIcons div.trialmulti {
	padding: 8px;
	background: #4a90e2;
	border-radius: 3px;
	display: inline-block;
	margin-right: 10px;
}

.trialsInner .mainTrialEntries .rightIcons div.glyphicon {
	padding: 10px;
	border-radius: 3px;
	cursor: pointer;
	background: #5a5b6e;
	display: inline-block;
	color: #fff;
}

.trialsInner .mainTrialEntries .rightIcons div.glyphicon span {
	color: #fff;
}

.technologyDiaInner.trialsInner .criteria p {
	color: #a5a5a5;
	font-size: 14px;
	margin-bottom: 0;
}

.technologyDiaInner.trialsInner .criteria span {
	color: #4a90e2;
	font-size: 14px;
}

.tech-inner-content-table .trialItemEntries .border-left {
	border-left: 1px solid #d8d8d8;
}

.tech-inner-content-table .trialItemEntries .border-left p {
	margin-bottom: 0;
	line-height: 10px;
}

.tech-inner-content-table .trialItemEntries .border-left p strong {
	color: #00b5e0;
	font-size: 14px;
	font-family: Helvetica Bold;
	font-weight: 400;
	letter-spacing: 0;
}

.tech-inner-content-table .trialItemEntries .border-left strong {
	color: #00b5e0;
	font-family: Helvetica Bold;
	font-size: 40px;
	font-weight: 400;
	letter-spacing: 0;
}

.tech-inner-content-table .trialItemEntries .border-left.certainty span,
.tech-inner-content-table .trialItemEntries .border-left.certainty strong {
	color: #5e5b6e;
}

.tech-inner-content-table .trialItemEntries .border-left span {
	font-size: 22px;
	padding: 5px;
	font-weight: 700;
}

/**************** Trial Components CSS *****************/
.enterTrial,
.selectbasecohort {
	margin-bottom: 30px;
}

.addTrialArms .techitemTitle {
	background: #f4f4f4 none repeat scroll 0 0;
}

.addTrialArms .addTrialArm,
.addTrialArms .techitemTitle {
	color: #000;
	font-weight: 800;
	height: 60px;
	margin-bottom: 20px;
}

.addTrialArms .addTrialArm {
	background: #e8f0f8;
}

.addTrialArms .addTrialArm select {
	color: #619ae0;
	border: 1px solid #ccc;
	font-size: 22px;
	font-weight: 400;
	margin-top: 7px;
	padding: 8px;
	width: 60%;
}

.addTrialArms .menuplusicon {
	background: #00b5e0 none repeat scroll 0 0;
	border-radius: 3px;
	display: inline-block;
	float: right;
	margin-top: 10px;
	padding: 8px 10px;
}

.addTrialContent input {
	border: 1px solid #ccc;
	border-radius: 3px;
	font-size: 18px;
	width: 500px;
	padding: 5px;
}

.saveTrialButton {
	background: #00b5e0 none repeat scroll 0 0;
	border-radius: 3px;
	display: inline-block;
	padding: 2px;
	text-align: center;
	vertical-align: middle;
	width: 40px;
}

.addTrialArms .techitemTitle h3 {
	border-radius: 3px;
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	margin: 0;
	padding: 20px 0 13px;
}

.saveTrialButton .glyphicon {
	color: #fff;
}

.selectbasecohort select {
	border: 1px solid #ccc;
	border-radius: 3px;
	font-weight: 400;
	font-size: 22px;
	color: #619ae0;
	padding: 5px;
	width: 60%;
}

.addTrialArms .removeicon {
	background: #c9c9c9 none repeat scroll 0 0;
	border-radius: 3px;
	display: inline-block;
	float: right;
	margin-top: 10px;
	padding: 8px 10px;
}

.addTrialArms .removeicon .glyphicon {
	color: #fff;
}

.trialButton.addNewTrial {
	display: inline-block;
	margin-top: 46px;
	float: right;
}

.trialButton.addNewTrial button {
	width: 135px;
}

.trialLists .editviewTrial .glyphicon {
	color: #dbdbdb;
	font-size: 14px;
}

.trialLists .editviewTrial a.view {
	padding-right: 20px;
}

.editviewTrial .edit .glyphicon,
.editviewTrial .remove .glyphicon,
.editviewTrial .view .glyphicon {
	color: #c4c4c4;
}

.editviewTrial .edit .glyphicon,
.editviewTrial .view .glyphicon {
	padding-right: 10px;
}

.triaName {
	color: #2c2c2c;
	font-family: Helvetica Bold;
	font-size: 13px;
	font-weight: 400;
	letter-spacing: 0;
}

.addBaseCohort {
	background: #f4f4f4 none repeat scroll 0 0;
	color: #000;
	font-weight: 800;
	height: 60px;
	margin-bottom: 5px;
}

.addBaseCohort .editviewTrial a.add {
	background: #00b5e0 none repeat scroll 0 0;
	border-radius: 3px;
	display: inline-block;
	float: right;
	margin-top: 10px;
	padding: 8px 10px;
	margin-right: 15px;
}

.addBaseCohort .editviewTrial a.remove,
.editTrial .removeBtn .remove {
	background: #c9c9c9 none repeat scroll 0 0;
	border-radius: 3px;
	display: inline-block;
	float: right;
	margin-top: 10px;
	padding: 8px 10px;
}

.addBaseCohort .editviewTrial a .glyphicon {
	color: #fff;
}

.editTrial .trialCohPat {
	border-bottom: 0;
}

.editTrial .enterTrial input {
	border-radius: 2px;
	font-size: 32px;
	width: 400px;
	border: 1px solid #acacac;
}

.editTrial .mainTrialEntries select {
	width: 100%;
	border: 1px solid #ccc;
	border-radius: 3px;
	color: #619ae0;
	font-size: 22px;
	font-weight: 400;
	padding: 5px;
}

.addBaseCohort h4 {
	font-size: 26px;
}

.editTrial .mainTrialEntries {
	margin-top: 20px;
}

.editTrial .tech-inner-content-table {
	display: inline-block;
	margin-top: 20px;
	width: 100%;
}

.editTrial .removeBtn {
	display: none;
}

/**************** Trial Components CSS end *****************/

.appendRemoveIcon .basecohort {
	width: 90%;
	display: inline-block;
}

.appendRemoveIcon .removeBtn {
	float: right;
}

.appendRemoveIcon .removeBtn .remove {
	margin-top: 0;
}

.appendRemoveIcon .removeBtn .remove .glyphicon {
	color: #fff;
}

.border-box {
	box-sizing: border-box;
}

.App .col-sm-2.col-xs-12 {
	height: 100%;
	position: absolute;
	padding: 0;
}

.App .col-sm-10.col-xs-12 {
	float: right;
	text-align: left;
}

#indexEventCohort {
	margin-left: 22px;
	width: 77%;
}

.cohortsInner .trialButton {
	display: inline-block;
	margin-top: 50px;
	float: right;
}

.outComeLists td {
	border-left: 2px solid #ebebeb;
	display: inline-block;
	vertical-align: top;
	font-size: 14px;
	padding: 10px;
	width: auto;
}

.outComeLists td span.blue {
	color: #3980d8;
}

.outComeLists td span,
.outComeLists td span.blue {
	font-family: Helvetica Bold;
	font-size: 36px;
	font-weight: 400;
}

.outComeLists td span {
	color: #5e6a72;
}

.accordianID span {
	color: #619ae0;
}

.appendCohortData.outComeLists td td {
	border-left: medium none;
}

.customTopIconStyle.patientTabIcon {
	width: 12px;
}

.customTopIconStyle.dataTab {
	width: 20px;
	margin-right: 5px;
}

.trialButton.trialLeftButton.addNewTrial {
	float: left;
}

.cohortPopover .popover-body label {
	color: #40424c;
	font-family: "roboto-regular";
	font-size: 14px;
	padding-right: 6px;
	font-weight: 500;
	overflow: hidden;
	margin: 0;
}

.cohortPopover .popover-body {
	overflow-y: scroll;
	max-height: 300px;
}

.automatedTooltip.details table tr td:nth-child(2) span {
	color: #333 !important;
}

.enterCohort.studydetail.detail_content label {
	font-size: 16px;
}

.technologyDia.mainTrialEntries.Study_d {
	background: none;
	margin-bottom: 0;
	margin-top: 0;
}

.automatedTooltip.details table tr td:nth-child(1) {
	padding-right: 10px !important;
}

.automatedTooltip.details table tr td:nth-child(2) {
	padding-right: 10px !important;
}

.automatedTooltip.detail span{
	white-space: nowrap;
}
/********* removing underline from anchor tags in Data Detail Page *********/
.historyData a {
	text-decoration: none;
	display: inline-block;
}

.padding_zero {
	padding-left: 0px;
}

/* .cohortPopover {
  padding-left: 10px;
} */
.cohortPopover h3.popover-title {
	border-bottom: 2px solid #ebebeb;
}

.cohortPopover .popover-body h3 {
	color: rgb(95, 95, 96);
	background: transparent;
	font-family: "roboto-regular";
	margin: 10px 10px 20px;
}

.cohortPopover .popover-body table {
	margin: 0 15px;
}

.main_left_div .defaultSpinner,
.preview-annotation .defaultSpinner {
	height: 120px;
	width: 120px;
	/* border-radius: 100%; */
	/* display: block; */
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	-moz-animation: rotateLoading 1.5s linear 0s infinite normal;
	-moz-transform-origin: 50% 50%;
	background-image: url("../images/default_spinner.svg");
	background-size: contain;
	background-repeat: no-repeat;
}

.v-loading-spinner {
	position: absolute;
	height: 100%;
	max-height: 768px;
	width: 100%;
	background: rgba(250, 250, 250, 0.5) url("../images/verantos_load.gif") no-repeat center center;
	/* border: 1px solid #f5f5f5; */
	z-index: 1;
}

.popover {
	min-height: 250px;
	min-width: 500px;
	z-index: 1039;
}

/******************** Data Management Modal CSS*************************/

.Data-Management {
	min-width: 70%;
	border-radius: 0;
}

.Data-Management .modal-body {
	/*  background: #f7f7f8;*/
	min-width: 78% !important;
}
.Data-Management .modal_body_content {
	min-height: 700px;
}

.panel-heading .batchDoc {
	height: 46px;
}

.upload_batch {
	margin-top: 18px;
}

.Data-Management a.narcissus_86uon7 {
	display: none;
}

.Data-Management a.narcissus_86uon7 circle {
	fill: #555;
}

.modal_body_content {
	display: inline-block;
	width: 100%;
	min-height: 400px;
	background: #fff;
	padding: 15px 25px;
	/* border: 1px solid #ddd;
  border-top: 3px solid #00b5e0; */
}

.modal-wrapper{
	padding: 20px;
}

.modal_body_content .cohortsInner .mainTrialEntries label {
	width: 23%;
	vertical-align: top;
}

.modal_body_content .popup_fields {
	display: inline-block;
	width: 40%;
	vertical-align: top;
}

.modal_body_content .Select {
	width: 100%;
	display: inline-block;
	margin: 0;
}

.modal_body_content .tab-content {
	display: inline-block;
	width: 100%;
	padding: 10px 50px;
}

.modal_body_content .help-block.error {
	margin-bottom: 0;
}

.modalHeader .itemTitle {
	margin-bottom: 0px;
}

.cohort_name {
	width: 18%;
	display: inline-block;
	vertical-align: middle;
}

.cohort_field {
	display: inline-block;
	width: 80%;
	vertical-align: middle;
	margin-left: -12px;
}

.image_profile img {
	width: 150px;
	height: 150px;
}

.detail_profile_tab h4 {
	font-size: 14px;
	margin-top: 0;
}

.nopadding.profile_Data_list {
	padding-top: 20px;
}

.addTrialArm.saveTrial.save_tril {
	padding-right: 14%;
	margin-top: 10px;
}

.addCohortCriteria.out_comes {
	padding: 0;
	margin-bottom: 15px;
}

.modal_body_content .popup_fields input#cohortName {
	margin-left: auto;
	height: 26px;
	font-weight: normal;
    padding-left: 12px;
    box-sizing: border-box;
    border: 1px solid rgba(156, 156, 156, .5);
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 12%);
    height: 35px;
}

.modal_body_content .popup_fields input#cohortName:focus{
	outline: none;
}

/*****************batch,document styling*****************/

.data_management {
	min-height: 320px;
	height: 100%;
	overflow-y: scroll;
}

.data_management .panel.panel-default span {
	font-size: 14px;
	color: #333;
	font-family: "roboto-regular";
}

.upload_batch span.Select-clear {
	display: none;
}

#accordion .data_management .panel.panel-default {
	background: none;
	margin-bottom: 0;
}

.ToogleSection .data_management .panel.panel-default .panel-heading h4 {
	display: inline-block;
	margin: 0;
	width: 100%;
	vertical-align: middle;
}

.ToogleSection .batch_doc .panel.panel-default .panel-heading:nth-of-type(odd) {
	display: block;
	background: #fff !important;
	border: none;
	box-shadow: none;
}

.data_management .panel {
	box-shadow: none;
	margin: 0;
}

.cohortsInner .mainTrialEntries .batch_upload {
	padding: 0 0 0 0;
	margin-top: 0;
}

.ToogleSection .batch_doc .panel.panel-default .panel-heading h4.panel-title.dataBatchOrg {
	padding-left: 39px;
}

/***********HeaderModal Styling************/

/***********************UserMangement***********************/
.tab_modal_table {
	overflow: hidden;
	margin: 0px 0px 20px 0px;
	text-align: left;
}

.tab_modal_table .ToogleSection .panel.panel-default .panel-heading .panel-title h4 {
	padding: 0;
	line-height: 16px;
}

.provider_add {
	padding: 0;
}

.SelectAccess {
	width: 182px;
	border-radius: 3px;
	background: #fff;
	display: flex;
	border-color: #c5c8cd;
}

.access_role {
	margin-left: -9px;
}

/**************************AccountManagement************************/

.profile_Data {
	width: 383px;
}

.dataStyle h3 {
	font-size: 14px;
}

.panel-heading.permission h4.panel-title {
	padding: 15px 0px 13px;
}

.ToogleSection .permission_doc .panel.panel-default .panel-heading {
	display: block;
	border: none;
	box-shadow: none;
}

.heading_cohart_data h3 {
	padding-left: 50px;
}

.modal_body_content h4.title_heading {
	font-weight: bold;
	margin-bottom: 0;
}

.panel-heading.permission h4.panel-title span {
	color: #333;
	font-size: 14px;
	font-weight: 500;
}

.panel-heading.batchDoc .sacred_heart {
	padding-left: 46px;
}

.heading_access h3 {
	padding-left: 15px;
}

.healthcare a {
	padding-left: 32px;
}

.healthcare a {
	padding-left: 24px;
}

.panel-heading .healthcare .accordion-toggle:before {
	float: none;
	font-size: 10px;
	padding-right: 15px;
}

.permission_org_header {
	padding-left: 32px;
}

.permission_role_header {
	padding-left: 43px;
}

.permission_org {
	padding-left: 32px;
}

.permission_role {
	padding-left: 44px;
}

.loction_add {
	text-align: center;
}

div.panel.panel-default .panel-heading {
	background-color: #fff;
	padding: 0 !important;
}
div.panel.panel-default .panel-heading .panel-title {
	padding: 10px 0 7px 0;
}
.upload_batch .mainTrialEntries {
	padding-left: 0;
}

.ToogleSection div.panel.panel-default:nth-of-type(even) .panel-heading.permission {
	background-color: #fbfbfb !important;
}

.addTrialArm.saveTrial.save_tril select.custom {
	padding-left: 24px;
	height: 26px;
	padding: 0;
	font-size: 14px;
	/*font-weight: 600;*/
	line-height: 25px;
}

.appendCohortData.label_critertion label {
	width: 25%;
}

.ToogleSection div.panel.panel-default:nth-of-type(odd) .panel-heading.permission {
	background-color: #fff !important;
}

.cohortsInner .mainTrialEntries .enterCohort .cohort_field {
	display: inline-block;
	width: 80%;
	vertical-align: middle;
	margin-left: 0;
}

.bold_heading_text {
	font-size: 18px !important;
}

.appendCohortData.measures_outcome {
	padding-left: 49px;
}

.calender_div {
	/* width: 20%; */
	display: inline-block;
	margin: 0 6px 0 0;
}

.addEventDate.saveTrial.calender {
	padding-left: 1.5%;
	margin-top: 10px;
}

/*************************responsive********************/

@media (max-width: 1336px) {
	.appendCohortData {
		padding-left: 40px;
	}
}

@media (max-width: 1292px) {
	.appendCohortData {
		padding-left: 20px;
	}

	.cohortsInner .mainTrialEntries .enterCohort .cohort_field {
		margin-left: -2px;
	}
}

button.close_modal {
	width: 35px;
	height: 35px;
	border: 1px solid;
	border-radius: 50%;
	position: absolute;
	right: -28px;
	top: -30px;
	text-align: center;
	padding: 0;
	font-size: 22px;
	font-weight: 800;
	background: #555;
	color: #d9d9d9;
}

.panel.panel-default span.patient_list {
	color: #40424c;
}

/*********************** Study Pages styling *******************************/

.technologyDia.mainTrialEntries.entercohort_div label {
	padding-right: 9px;
}

.technologyDia.mainTrialEntries.entercohort_div {
	margin-bottom: 30px;
}

.itemTitle .heading_title h3 {
	margin: 0;
	font-size: 16px;
	color: #2b2929;
	vertical-align: middle;
	padding-left: 5px;
	padding: 11px 0px;
}

label.lead.studyCohortValue {
	padding-left: 0px;
}

.base_cohort {
	padding-left: 60px;
}

.addOutcome.table_infarction,
.addOutcome.table_infarction .appendCohortData.outComeLists {
	padding: 0;
}

.table_main_table {
	margin-bottom: 20px;
	/* padding-left: 0;*/
}

.table_main_table label.lead {
	margin-bottom: 0px;

	font-size: 15px;
}

.table_main_table.first_table table tr td:first-child {
	width: auto;
}

.cohortsInner .mainTrialEntries .enterCohort.studydetail {
	padding: 0 0 9px 15px;
}

.nopadding {
	padding: 0;
}

.text-right.trialButton.addNewTrial {
	padding-right: 0;
}

.text-left.trialButton.addNewTrial {
	padding-left: 0;
}

.editStudy {
	margin-bottom: 25px;
	display: inline-block;
	width: 100%;
}


.exitEditPage {
	width: 100%;
	color: #ddd;
}


/* 
.Select.uniqueTriaArm.custom.select_cohart.Select--single.is-clearable.is-searchable.has-value.Select--single>.Select-control .Select-value {
  padding-left: 12px;
} */

/*************************** ManualAnnotationTootip start******************************/
.manualPopover {
	margin: 0 0 0 6px;
}

.Select.uniqueTriaArm.custom.manualAnnuatation.nopadding.Select--single.is-clearable.is-searchable {
	margin: 0;
}

.manualAnnuatation span.Select-clear-zone span.Select-clear {
	display: none;
}

/* .popup_edit .Select.uniqueTriaArm.custom.Select--single.is-clearable.is-searchable .Select-control {
  height: 22px;
  padding: 0;
  display: block;
  border-radius: 2px;
  font-size: 11px;
} */

.popup_edit .Select.uniqueTriaArm.custom.Select--single.is-clearable.is-searchable .Select-control .Select-placeholder {
	color: #40424c;
	top: 0;
	font-size: 13px;
	padding: 0 6px;
}

.annotaionListPopover.heading_title h3.popover-title {
	border-bottom: none !important;
	margin: 0;
	padding: 20px 40px 0;
	height: auto;
}

.popup_edit label {
	display: block;
	font-size: 13px;
	color: #222;
	margin: 0;
}

.popup_edit .Select {
	margin: 0 0 5px;
	display: block;
}

button.addButton.btn.btn-default {
	display: block;
	margin-bottom: 20px;
}

span.code_type {
	/* height: 32px; */
	display: block;
	font-size: 13px;
	white-space: nowrap;
}

span.code_type input.codeAnnotation {
	width: 300px;
	height: 32px;
	border: 1px solid rgba(128, 128, 128, 0.42);
	border-radius: 5px;
	color: #40424c;
	padding-left: 8px;
}

.popup_edit.edit_pencil span.Select-clear-zone span.Select-clear {
	display: none;
}

img.edit_pencil {
	width: 18px;
	margin: -17px 0px 0px 6px;
	height: 17px;
}

a.summaryDataDetail {
	float: right;
	font-size: 13px;
	padding-top: 9px;
}

.editDetail img {
	width: 27px;

	cursor: pointer;
}

.editFont span .Select-value-label {
	font-size: 11px;
}

.historyData a img.manualPopover.onEditode {
	/* cursor: url(/images/edit_pencil.png), auto; */
}

.editFont.Select--single > .Select-control .Select-value {
	/* top: 0; */
	/* padding-left: 3px; */
	/* margin-bottom: auto;
  line-height: normal;
  padding-top: 4px; */
  text-transform: capitalize;
}
.uniqueTriaArm .Select-menu-outer {
	text-transform: capitalize;
}

button.addButton.btn.btn-default {
	padding: 2px 16px;
	font-size: 11px;
	border-radius: 3px;
	background: #00b5e0;
	color: #fff;
}

button.addButton.anotationCancelButton.btn.btn-default {
	float: right;
	padding: 6px 31px;
	font-size: 15px;
	margin-left: 10px;
}

button.addButton.annotationsaveBtn.btn.btn-default{
	float: right;
	padding: 3px 20px;
	font-size: 12px;
	margin-left: 10px;
	margin-bottom: 0;
}

.manual span.Select-clear {
	display: none;
}

.edit_manual_Icon {
	float: right;
}

div#popover-positioned-right.warningTooltip {
	min-height: 87px;
	padding-top: 25px;
	top: 37.2813px;
}

a.Add-manualAnnotation:hover {
	background-color: #000 !important;
	color: #fff !important;
}

.uniqueTriaArm.custom.editFont.manual span.Select-arrow {
	display: none;
}

.Select.uniqueTriaArm.custom.editFont.manual.Select--single.is-clearable.is-searchable {
	margin-left: 3px;
	width: 98%;
}

.automatedTooltip.details span {
	/* padding: 0px 4px;
  margin-bottom: 4px; */
}

.manual.Select.uniqueTriaArm.custom.Select--single.is-clearable.is-searchable .Select-control .Select-placeholder {
	color: #40424c;
	top: 0px;
	font-size: 11px;
	line-height: 26px;
}

.enterCohort.Automated_versus label {
	font-size: 18px;
}

.testClass {
	z-index: 999;
}

.appComponent {
	z-index: 999;
}

div#rapidPopOver {
	z-index: 5;
}

/* div#popover-positioned-bottom {
  position: absolute;
  z-index: 4;
} */

/*************************** ManualAnnotationTootip End******************************/

/****************************Summary page****************************/
.tech-inner-content:nth-child(even) {
	/*background: #fdfbfb;*/
}

.tech-inner-content-item.heading_summery {
	display: block;
	height: 35px;
	line-height: 35px;
	background: #f4f4f4;
	margin-bottom: 3px;
}

.tech-inner-content-item.heading_summery span {
	color: #40424c;
	font-weight: bold;
	font-size: 13px;
}

.head_summary {
	width: 100%;
	float: left;
}

.techitemTitle.head_summary {
	margin-top: 5px;
}

.eventName span {
	color: #40424c;
}

td span.report_filter {
	padding-left: 18px;
}

.link_color a {
	color: #619ae0;
}

.SimpleDiv h3 {
	font-size: 14px;
	margin: 0;
	padding: 9px;
}

.rdt.rdtOpen input.form-control2 {
	border-radius: 3px;
	font-size: 13px;
	margin-bottom: 6px;
	/* border: 1px solid #bbb; */
	padding-left: 4px;
	font-weight: normal;
    padding-left: 12px;
    box-sizing: border-box;
    border: 1px solid rgba(156, 156, 156, .5);
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 12%);
    height: 35px;
}
.rdt.rdtOpen input.form-control2:focus{
	outline: none;
}

/*************** User Story AP-1178 MSP CSS added for concept popover *****************/
.conceptPopOverStyle.popover {
	min-height: 80px !important;
	padding-top: 15px;
	padding-left: 15px;
}

td:first-child {
	/* padding-left: 18px; */
}

td {
	padding: 6px;
}

/*************** User Story AP-1304 summary analytics filter label *****************/
.filtersContainer {
	padding-left: 48px;
	padding-top: 16px;
}

.filterRow {
	margin-bottom: 8px;
}

.filterlabel {
	width: 8%;
	display: inline-block;
}

.filtersContainer select {
	border-radius: 3px;
	padding: 4px;
	width: 240px;
}

.filtersContainer select:disabled {
	color: graytext;
}

/*************** User Story AP-1304 summary analytics filter label *****************/
/*************** User Story AP-1398 summary analytics filter label *****************/
.hide {
	display: none;
}

/*************** User Story AP-1398 summary analytics filter label *****************/
/*************** User Story AP-1444 summary analytics spinner *****************/
.summaryAnalyticsSpinner {
	height: 15px;
	width: 15px;
	border-radius: 100%;
	display: inline-block;
	top: 0px;
	margin: auto;
	background-color: transparent;
	border: 3px solid transparent;
	border-color: #eee #eee #444 #444;
	-webkit-animation: rotateLoading 1s linear 0s infinite normal;
	-webkit-transform-origin: 50% 50%;
	-moz-animation: rotateLoading 1.5s linear 0s infinite normal;
	-moz-transform-origin: 50% 50%;
	animation: rotateLoading 1.5s linear 0s infinite normal;
}

/*************** User Story AP-1444 summary analytics spinner *****************/
/*************** User Story AP-1676 Manual annotation detail component *****************/

.annotation-header-toolbar {
	/* float: right; */
}

.popover-header {
	border-bottom: 2px solid #ebebeb;
	font-size: 18px;
	padding: 10px 15px 6px;
	color: #333;
	background: transparent;
	font-family: "roboto-regular";
}

.annotation-title {
	/* margin-top: 12px; */
	/* margin-bottom: 12px; */
	margin: 0;
	font-size: 18px;
	display: flex;
	justify-content: space-between;
	/* align-items: center; */
}

.annotation-header-toolbar-icon {
	margin-left: 8px;
	height: 24px;
	width: 24px;
}

.annotaion-detail-field {
	margin-top: 4px;
}

.annotaion-detail-field-label {
	margin-bottom: 8px;
	display: block;
	background-color: #f4f4f4;
	padding: 4px 24px;
	font-weight: unset;
}

.annotaion-detail-field-value {
	padding-left: 24px;
	margin-bottom: 12px;
}

.popover-container {
	display: -ms-flexbox;
	/* IE10 */
	display: flex;
	-ms-flex-wrap: wrap;
	/* IE10 */
	flex-wrap: wrap;
	margin-bottom: 8px;
	text-transform: capitalize;
}

.col-50 {
	-ms-flex: 50%;
	/* IE10 */
	flex: 50%;
}

.annotation-col-left {
	padding-right: 8px;
}

.annotation-col-right {
	padding-left: 8px;
}

.popover-footer {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 16px;
}

.green-button {
	width: 120px;
	background: #00b5e0;
	color: #fff;
	margin-left: 8px;
}

.green-button:hover {
	color: #eee;
}

.green-button:active {
	color: #eee;
}

.modal-message,
.modal-action {
	text-align: center;
}

.modal-message {
	margin-top: 32px;
}

.modal-action {
	margin-bottom: 24px;
}

.modal-footer {
	border-top: none;
}

/*************** User Story AP-1676 Manual annotation detail component *****************/
/*************** User Story AP-1740 Automated annotation list component *****************/
.annotation-link {
	color: #0000ee;
	cursor: pointer;
}

.popover-annotation-list {
	flex: 100%;
}

.popover-annotation-list .popover-annotation-list-item {
	height: unset;
	padding: 4px 32px;
	margin: unset;
	line-height: unset;
}

/*************** User Story AP-1740 Automated annotation list component *****************/

/*************** User Story AP-1751 Add User Component *****************/
.user-header-toolbar {
	float: right;
}

.user-header-toolbar-icon {
	margin-left: 8px;
	margin-top: 5px;
	height: 24px;
	width: 24px;
}

.user-label {
	width: 150px;
	display: inline-block;
}

.user-form-control {
	margin-bottom: 10px;
	border-radius: 4px;
	border: 1px solid #ccc;
	height: 35px;
	width: 40%;
	padding-left: 4px;
}

.user-error_field .alert {
	margin-top: 15px;
	margin-left: 5px;
	width: 420px;
}

.techPagination {
	background-color: #f4f4f4;
	height: 36px;
}

.techPagination ul.pagination {
	margin: 0px;
}

.techPagination ul.pagination li {
	width: auto;
	display: inline-block;
	cursor: not-allowed;
}

.techPagination ul.pagination li a:focus,
.techPagination ul.pagination li a:active {
	outline: none;
	border: none;
	background-color: #f4f4f4;
}

.techPagination ul.pagination li a:focus,
.techPagination ul.pagination li a:active {
	outline: none;
	border: none;
	background-color: #f4f4f4;
}

.techPagination ul.pagination li.disabled a {
	cursor: not-allowed;
}

.techPagination ul.pagination li a {
	background-color: transparent;
	border-color: transparent;
	color: mediumseagreen;
}

.techPagination ul.pagination li.active a {
	color: #000;
	text-decoration: underline;
}

.techPagination ul.pagination li.active {
	background-color: transparent;
	border-color: transparent;
}

.techPagination ul.pagination li.active a:hover {
	background-color: transparent;
	border-color: transparent;
}

.techPagination ul.pagination li.active a:focus {
	background-color: transparent;
	border-color: transparent;
}

.techPagination .first {
	width: 22px;
}

.techPagination .prev {
	width: 22px;
}

.techPagination .next {
	width: 22px;
}

.techPagination .last {
	width: 22px;
}

/*************** User Story AP-1751 Add User Component *****************/
.icon_study {
	height: 30px;
	width: 30px;
	display: inline-block;
	background: url("../images/study-small-black.svg") no-repeat;
	background-size: contain;
}

.icon_cohort {
	height: 30px;
	width: 30px;
	display: inline-block;
	background: url("../images/cohort-small-black.svg") no-repeat;
	background-size: contain;
}

.icon_patient {
	height: 23px;
	width: 30px;
	display: inline-block;
	background: url("../images/patient-small-black.svg") no-repeat;
	background-size: contain;
	background-position: 8px 0px;
	margin-top: 1px;
	margin-bottom: 6px; /* margin added for uniform height of header (height = 30px)*/
}

.icon_data {
	height: 25px;
	width: 26px;
	display: inline-block;
	background: url("../images/doc-small-black.svg") no-repeat;
	background-size: contain;
	margin-bottom: 5px; /* bottom margin required for uniform of height of header (height = 30px)*/
}

.icon_technology {
	height: 30px;
	width: 30px;
	display: inline-block;
	background: url("../images/header_technology.png") no-repeat;
	background-size: 100%;
}

/******************** following styles for accordion used in popup ***/
.popup-accordion .panel-heading {
	padding: 1px 16px;
	margin-bottom: 4px;
	background: #fbfbfb;
}
.popup-collapse {
	padding: 4px 60px;
}
.popup-collapse-accordian {
	padding: 6px 10px;
}

.popup-collapse-accordian ul li{
	padding-left: 20px;
}
.popup-accordion .accordion-toggle {
	cursor: pointer;
}
/******************** above styles for accordion used in popup ***/
.hyperlink,
.hyperlink * {
	color: #297add !important;
	cursor: pointer !important;
	/* margin-bottom: 4px; */
}

.hyperlink .highlighted {
	color: #fff !important;
}

.hyperlink .highlighted2 {
	color: #fff !important;
}

.listNav {
	cursor: pointer;
}
.btn-edit-profile-img {
	width: 29px;
	height: 29px;
	padding: 3px;
	margin: -30px 0 0 120px;
	background: rgba(128, 128, 128, 0.3);
}
.btn-edit-profile-img .glyphicon-camera {
	color: #fff;
	font-size: 20px;
}
.panel .light-background {
	background: #fbfbfb !important;
}
.panel .light-background:hover {
	background: #e8f0f8 !important;
}
.panel-group .panel + .panel {
	margin-top: 0;
}
#manual-annotation-popover .popover-title {
	border-bottom: 2px solid #eaeaea;
	width: 96%;
	margin: 0 auto;
}
.dataanalyse a {
	cursor: pointer;
}
.dataanalyse .btn-analyse {
	background-color: #00b5e0;
	border-color: #00b5e0;
}

.dataanalyse .btn-analyse .glyphicon {
	color: white;
}

.dataanalyse .fomatted_text {
	margin-bottom: 20px;
}

.dataanalyse .automatedTooltip th,
.dataanalyse .automatedTooltip td {
	padding: 6px !important;
}
.dataanalyse .automatedTooltip th .content {
	color: #333;
	background: #f4f4f4;
	padding: 5px;
	margin-left: 5px;
	min-width: 200px;
}

.dataanalyse .automatedTooltip td .content {
	color: #333;
	padding: 5px;
	margin-left: 5px;
	min-width: 200px;
}
.dataanalyse .txt {
	color: #297add;
}
.dataanalyse .fomatted_text .section-header {
	background: #f4f4f4 none repeat scroll 0 0;
	height: 40px;
	color: #40424c;
	text-align: left;
	line-height: 15px;
	width: 100%;
	clear: both;
	margin: 10px 0;
	padding-left: 20px;
	font-size: 16px;
	padding-top: 14px;
}
#popover-positioned-annotation {
	width: 100%;
}

#popover-positioned-annotation.preview {
	max-width: 1200px;
	width: auto;
}
#popover-positioned-annotation.preview .automatedTooltip.secondiv {
	width: 50%;
	display: inline-block;
	vertical-align: top;
}
#popover-positioned-annotation.preview .list {
	margin: 0;
	padding: 0;
}

#popover-positioned-annotation.preview .list li.preview-list {
	display: inline-block;
	width: 49%;
	background-color: #fff;
	vertical-align: top;
}

#popover-positioned-annotation.preview .list li.preview-list:hover {
	background: none;
}

#popover-positioned-annotation.preview .list .header {
	color: #333;
	font-size: 13px;
	background: #f4f4f4;
	padding: 2px 6px;
	font-weight: 500;
	margin-bottom: 2px;
	padding-left: 15px;
	padding-top: 6px;
}

#popover-positioned-annotation.preview .list .text {
	color: #333;
	font-size: 13px;
	padding: 5px 15px;
}
.automated-annotation-popover td span{
	width:145px;
}

.automatedTooltip .info-wrapper {
	display: flex;
	direction: row;
	justify: space-between;
}
.automatedTooltip .column-div {
	width: 33.33%;
	margin: 8px;
	min-width: 220px;
	/* display:  */
}
.automatedTooltip .detail-header {
	padding: 4px;
	padding-left: 20px;
	color: #333;
	font-size: 13px;
	word-spacing: 0;
	background: #f4f4f4;
}
.automatedTooltip .detail-info {
	padding: 5px 4px 5px 20px;
	min-height: 40px;
	line-height: 28px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.panel-default > .panel-heading {
	color: #333;
	background-color: #f5f5f5;
	border-color: #ddd;
}
.popover {
	font-family: 'roboto-regular';
}
.App-minusIcon {
	width: 25px;
	/* margin-left: 10px; */
	cursor:pointer;
	padding-top: 8px;
}
.App-plusicon{
	width: 25px;
	/* margin-left: 10px; */
	cursor:pointer;
	padding-top: 8px;
}

.patient-level-annotation-pop .modal-body {
	min-height: 300px;
}

.patient-level-annotation-pop .automatedTooltip {
	min-height: auto;
}

.patient-level-outcome-popover span.code_type input.codeAnnotation {
	width:auto;
}
.patient-level-outcome-popover .code_type{
	text-transform: capitalize;
}
.select-study-popup .customselect {
    width: 45%;
    text-align: center;
    margin: 0 auto;
}
.nav-side-link{
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top:35px;
}
.ver-logo {
	height: 35px;
	width: 35px;
}
.ver-logo-wrapper{
	margin-top: 24px;
    display: flex;
    justify-content: center;
}
#document-manual-annotation-popup .automatedTooltip {
    min-height: 350px;
    display: flex;
    flex-direction: column;
	height: 600px;
}
#document-manual-annotation-popup .automatedTooltip-view {
    min-height: 350px;
    display: flex;
    flex-direction: column;
}
#document-manual-annotation-popup .automatedTooltip .popover-content-wrapper {
    flex-basis: 90%;
    height: 560px;
    overflow: auto; 
	
}
#document-manual-annotation-popup .automatedTooltip .error-container span {
	font-size: 13px;
	color: #ea5848;
	padding-left: 10px;
}
#document-manual-annotation-popup  .automatedTooltip .popover-footer-btn {
    flex-basis: 5%;
}
.study-validity-navlink {
	color: #297add;
    text-decoration: none;
	cursor: pointer; 
}
@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.fade-enter {
	opacity: 0;
}
.fade-enter-active {
	animation: fadeInAnimation ease 3s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}
.modal-inner-footer {
	position: absolute;
	bottom: 20px;
	right: 10%;
}
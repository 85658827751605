.pending-encounter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px 10px 10px;

}

.pending-encounter-header .text {
    font-size: 20px;
}

.pending-encounter-header .drpdown{
    width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.pending-encounter-header .drpdown .customselect{
    width:200px
}